module.exports = {
  defaultTitle: 'agipany',
  author: 'Agata Roszkowska',
  url: 'https://agataroszkowska.co.uk',
  legalName: 'Agata Roszkowska',
  defaultDescription:
    'My name is Agi, I’m a digital designer and currently a Creative Lead at Sustainability driven company. My main focus is digital, it’s my world, but I sometimes dive into illustration, animation and branding projects, just to change things up a bit. Regardless of the medium, usability is always at the heart of what I do.',
  language: 'en',
  socialLinks: {
    twitter: 'http://www.twitter.com/agataroszkowska',
    linkedin: 'https://www.linkedin.com/in/agata-roszkowska-250a1060/',
    instagram: 'https://www.instagram.com/agipany/',
    behance: 'https://www.behance.net/aaagi',
  },
  googleAnalyticsID: 'UA-37237975-2',
  themeColor: '#d5b443',
  backgroundColor: '#f9f3dd',
  faviconPath: `./static/favicon.jpg`, // for example: ./static/favicon/favicon-512.png
  social: {
    twitter: '@agataroszkowska',
  },
  contact: {
    email: 'design@agataroszkowska.co.uk',
  },
  foundingDate: '2021',
};
