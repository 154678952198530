/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import Thumbnail from 'static/favicon.jpg';
import logo from 'assets/images/logo_corner.png';

import {
  url,
  defaultDescription,
  social,
  defaultTitle,
  socialLinks,
  contact,
  legalName,
  foundingDate,
  themeColor,
  language,
} from 'data/config';
import { ZoomContext } from './ZoomContext';

export default ({ title: pageTitle, pathname }) => {
  const title = pathname !== '/' ? `${pageTitle} | ${defaultTitle}` : defaultTitle;
  const structuredDataOrganization = `{ 
		"@context": "http://schema.org",
		"@type": "Organization",
		"legalName": "${legalName}",
		"url": "${url}",
		"logo": "${logo}",
		"foundingDate": "${foundingDate}",
		"founders": [{
			"@type": "Person",
			"name": "${legalName}"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"contactType": "customer service"
		}],
		"sameAs": [
			"${socialLinks.twitter}",
			"${socialLinks.linkedin}",
			"${socialLinks.instagram}",
			"${socialLinks.behance}"
		]
  }`;

  const [enableZoom] = useContext(ZoomContext);
  return (
    <Helmet bodyAttributes={{ class: 'root' }}>
      <meta name="description" content={defaultDescription} />
      <meta name="image" content={Thumbnail} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={defaultTitle} />
      <meta property="og:image" content={Thumbnail} />

      <meta name="theme-color" content={themeColor} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={socialLinks.twitter} />
      <meta name="twitter:site" content={social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={defaultTitle} />
      <meta name="twitter:image:src" content={Thumbnail} />
      <script type="application/ld+json">{structuredDataOrganization}</script>

      <title>{title}</title>
      <html lang={language} dir="ltr" />
      <meta
        name="viewport"
        content={`width=device-width, initial-scale=1.0, user-scalable=${enableZoom ? '1' : '0'}`}
      />
    </Helmet>
  );
};
