import styled, { css } from 'styled-components';

export const PageWrapper = styled.main`
  padding-top: 160px;
`;

export const TermsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.29;

  li {
    padding: 0 10px;
    color: ${({ theme }) => theme.colors.text};
  }

  ${({ hasSeparator }) =>
    hasSeparator &&
    css`
      li:not(:first-child) {
        padding-left: 0;

        &:before {
          content: '|';
          padding-right: 10px;
        }
      }
    `};
`;

export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.colors.heading};
  font-family: 'WindsorD-Reg', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  text-align: ${({ textAlign }) => textAlign || `center`};
  font-size: ${({ fontSmaller }) => (fontSmaller ? `3rem` : `5.8rem`)};
  line-height: 0.84;
  margin-bottom: ${({ marginSmaller }) => (marginSmaller ? `16px` : `64px`)};
  ${({ theme }) => theme.mq.mobile} {
    margin-bottom: 24px;
  }
`;

export const StyledH2 = styled.h2`
  color: ${({ theme }) => theme.colors.heading};
  font-family: 'WindsorD-Reg', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 3rem;
  line-height: 1.29;
  font-weight: 300;
`;

export const StyledContent = styled.div`
  p,
  ol,
  ul {
    font-size: ${({ fontSmaller }) => (fontSmaller ? `1.5rem` : `2rem`)};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledSVG = styled.svg`
  max-width: 100%;
  height: auto;
`;
